<template>
  <div class="full-page overflow-auto" ref="container">
    <FavorContainer
      v-if="flag('stage_1_step_1') && flag('stage_1_step_1_favor') == false"
      @close="phase1CardNext"
      :stage="stage"
      :step="1"
    ></FavorContainer>
    <FavorContainer
      v-if="flag('stage_1_phase2Clear') && flag('stage_1_step_2_favor') == false"
      @close="phase2bonusDone"
      :stage="stage"
      :step="2"
    ></FavorContainer>
    <FavorContainer
      v-if="flag('stage_1_phase3Clear') && flag('stage_1_step_3_favor') == false"
      @close="phase3bonusDone"
      :stage="stage"
      :step="3"
      :maximum_step="1"
      :stageFinished="true"
    ></FavorContainer>

    <div class="position-relative stage-content" ref="stage">
      <stageHeader :character="stageCharacter" :stageLocation="stageLocation"></stageHeader>
      <moreContent @click="showProgressUpdate = false" v-if="showProgressUpdate"></moreContent>
      <img v-if="!flag('stage_1_phase1Clear')" class="img-fluid" src="/img/nanyang.jpg" alt="" />
      <img v-else class="img-fluid" src="/img/nanyang_result.jpg" alt="" />
      <img class="hint-img" src="/img/nanyang-hint.svg" alt="" />
      <stageMessage
        :user="user"
        :stageCharacter="siteCharacter.name"
        :data="phase1Messages"
        :stage="stage"
        :step="1"
        v-on:stageClear="stageClear"
        v-on:hintAdd="parse1Hint"
      ></stageMessage>
      <checkPosition
        v-if="flag('stage_1_phase1Clear')"
        :location="check1stPosition"
        :local="[25.044661, 121.516125]"
        v-on:showIgStory="showPhase"
      ></checkPosition>
      <!--      <transition name="fade">-->
      <igStory :video="phase1IgStory" v-if="flag('stage_1_check1stPlace')"></igStory>
      <!--      </transition>-->
      <stageMessageNoInput :data="phase1IgStoryMsg" v-if="flag('stage_1_check1stPlace')"></stageMessageNoInput>
      <div v-if="flag('stage_1_phase2Block')">
        <slider :slides="guessNameSlide"></slider>
        <stageMessage
          :user="user"
          :stageCharacter="siteCharacter.name"
          :data="phase2Messages"
          :stage="stage"
          :step="2"
          v-on:hintAdd="parse2Hint"
          v-on:stageClear="phase2MsgClear"
        ></stageMessage>
      </div>
      <div class="" v-if="flag('stage_1_phase2Clear')">
        <photoMission
          :photo="photoMission"
          :stage="stage"
          @next="final"
          @final="final"
          @showStageFinishedFavor="showStageFinishedFavor"
        >
          <stageMessageNoInput :data="photoMissionMsg"></stageMessageNoInput>
        </photoMission>
      </div>
      <mainMenu></mainMenu>
    </div>
  </div>
</template>

<script>
import mainMenu from "@/views/part/mainMenu";
import stageHeader from "@/views/part/stageHeader";
import checkPosition from "@/views/part/checkPosition";
import CharacterFromVuex from "@/Mixins/CharacterFromVuex";
import igStory from "@/views/part/igStory";
import stageMessageNoInput from "@/views/part/stageMessageNoInput";
import stageMessage from "@/views/part/stageMessage";
import slider from "@/views/part/slider";
import photoMission from "@/views/part/photoMission";
import moreContent from "@/views/part/moreContent";

import { mapGetters, mapActions } from "vuex";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import UserFromVuex from "../Mixins/UserFromVuex";
import StageFromMixin from "../Mixins/StageFromMixin";
import FavorContainer from "./favor/FavorContainer";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import FavorFromMixin from "../Mixins/FavorFromMixin";
import Avatar from "@/Mixins/Avatar";
import ShowUpdate from "@/Mixins/ShowUpdateMixin";

export default {
  name: "nanyang",
  mixins: [
    Avatar,
    ShowUpdate,
    CharacterFromVuex,
    WebSocketMixin,
    UserFromVuex,
    StageFromMixin,
    TeamFromVuex,
    FavorFromMixin
  ],
  components: {
    mainMenu,
    stageHeader,
    checkPosition,
    igStory,
    stageMessage,
    stageMessageNoInput,
    slider,
    photoMission,
    FavorContainer,
    moreContent
  },
  computed: {
    ...mapGetters("Character", ["getRoleByName"]),
    ...mapGetters("Stage", ["flags_check"]),
    // flagsCheck(){
    //   return this.flags_check([
    //     'stage_1_phase1Clear',
    //     'stage_1_phase2Clear'
    //   ]);
    // },
    stage_1_phase2Block() {
      return this.flag("stage_1_phase2Block");
    },
    stageCharacter() {
      return this.getRoleByName(this.siteCharacter.name);
    }
  },
  // watch:{
  //   flagsCheck(val,oldVal){
  //     console.log(val, oldVal)
  //     if(val!==oldVal){
  //       this.moreContent=true;
  //     }
  //   }
  // },
  watch: {
    stage_1_phase2Block(val, oldVal) {
      if (val !== oldVal) {
        this.fakeFullScreen({ status: false });
      }
    }
  },
  data() {
    return {
      showProgressUpdate: false,
      showProgressUpdateFlags: [
        "stage_1_phase1Clear",
        "stage_1_phase2Block",
        "stage_1_phase2Clear",
        "stage_1_check1stPlace"
      ],
      stage: {
        stage_id: 1
      },
      siteCharacter: {
        name: "小希"
      },
      stageLocation: {
        time: "高一上",
        location: "南陽街"
      },
      check1stPosition: {
        nextPhase: "stage_1_check1stPlace",
        position: [25.044661, 121.516125],
        distance: 50
      },
      phase1Messages: {
        unlockFlag: "stage_1_phase1Clear",
        noMsgLikes: true,
        character: "小希",
        defaultMsg: [
          {
            name: "小希",
            content: "三角謎題好難喔……這題要寫些什麼呢？"
          }
        ],
        answer: ["最習不教的戀愛校", "最習校", "最不愛"],
        echoOfanswer: "終於解開了！用心練習也沒這麼難嘛",
        hintIndex: 0,
        hint: [
          "（1/4）照片中有個『最』字，『最』字還有出現在其他地方嗎？<br>（使用提示會減少得到的好感值）",
          "（2/4）小希貼文的文字中有個『最』字，三角形圖案跟箭頭方向代表什麼意思呢？<br>（使用提示會減少得到的好感值）",
          { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
          "（3/4）從小希貼文的文字中找到『最』字，將『最』字當作三角形頂點，順著方向、將經過的文字依序找出來<br>（使用提示會減少得到的好感值）",
          { content: "放棄了嗎？再次點擊提示按鈕查看答案" },
          "最習不教的戀愛校"
        ]
      },
      phase1IgStory: {
        src: "nanyang",
        poster: "mc/2.jpg",
        nextPhase: "stage_1_phase2Block"
      },
      phase1IgStoryMsg: [
        {
          name: "小希",
          content:
            "高中開學第四天，考上北一女的興奮早就不見了，現在只剩下緊張。班上的同學都好活潑好優秀，我個性比較怕生，到現在同學的名字都還記不熟，小桃是我第一個交到的好朋友。"
        }
      ],
      guessNameSlide: {
        width: 600,
        height: 800,
        imgs: ["ny-slide1.jpg", "ny-slide2.jpg", "ny-slide3.jpg"]
      },
      phase2Messages: {
        unlockFlag: "stage_1_phase2Clear",
        hintIndex: 0,
        msgLikes: "赤木和其他20個人都說讚",
        character: "小希",
        defaultMsg: [
          {
            name: "小桃",
            content: "這個給你，我的名字"
          },
          {
            name: "小希",
            content: "糟糕！小桃我也還沒記住你的本名耶 😓"
          },
          {
            name: "小彥",
            content:
              "這些圖案好像剛剛在路上有看到過？沿著南陽街十字路口走一走、找一找好了。這裡往來車輛很多要小心安全唷！"
          }
        ],
        answer: "孫雨葳",
        echoOfanswer: [
          "沒錯，就是孫雨葳！我的名字你們可別忘了喔 😝",
          "叫我小桃就可以了，然後這位是春哥",
          "小希、小桃、春哥，很高興認識妳們 😄",
          "他們是花輪、赤木、白爛，和胖子！",
          "走吧，時間來不及了，我們先佔位置，你先去幫小桃買飲料吧！",
          "好，沒問題！",
          "😊"
        ],
        echoOfCharacter: ["小桃", "小桃", "小彥", "小彥", "小希", "小彥", "小希"],
        hint: [
          "（1/4）紙條上的圖畫似乎跟南陽街的街道景色有關係<br>（使用提示會減少得到的好感值）",
          "（2/4）圖畫中缺少的部分好像都是國字的某一部分<br>（使用提示會減少得到的好感值）",
          { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
          "（3/4）從招牌上找出來的文字各別是：子、系、雨、艹、威<br>（使用提示會減少得到的好感值）",
          { content: "放棄了嗎？再次點擊提示按鈕查看答案" },
          "孫雨葳"
        ]
      },
      photoMission: {
        img: "photo-nanyang.jpg",
        flag: "stage_1_clear",
        finish: "stage_1_photo_finished",
        local: [25.044661, 121.516125],
        flyTo: [25.044661, 121.516125]
      },
      photoMissionMsg: [
        {
          name: "小希",
          content:
            "很幸運的在剛開學就認識最要好的好朋友——小桃，讓陌生的新環境不再那麼惶恐，進而又認識了你們一群好朋友，以及，一個我很喜歡的男生⋯⋯"
        },
        {
          name: "提示",
          content:
            "你們可以參考範例照片的地點和姿勢、重現當年的回憶；或是自行選擇你們喜愛的地點、姿勢或方式，拍照並上傳照片，<strong>照片請用正方形格式拍攝，超出將會被剪裁。</strong>"
        }
      ]
    };
  },
  mounted() {
    this.$refs.container.scrollTop = this.$refs.stage.scrollHeight;
    this.$refs.container.addEventListener("scroll", this.handleScroll);
  },
  updated() {
    // this.$refs.container.scrollTop = this.$refs.stage.scrollHeight;
  },
  methods: {
    ...mapActions("Video", ["fakeFullScreen"]),
    handleScroll() {
      if (this.showProgressUpdate) {
        this.showProgressUpdate = false;
      }
    },
    final() {
      this.unlockStage(1);
      this.setFlag({ key: "stage_1_photo_finished", status: true });
      // this.setFlag({ key: "stage_1_location", status: [25.045966, 121.518125] });
    },
    stageClear: function() {
      // console.log("clear")
      if (this.flag("stage_1_phase1Clear")) {
        return;
      }
      this.setFlag({ key: "stage_1_phase1Clear", status: true });
      this.setFlag({ key: "stage_1_phase1ClearCard", status: true });
      this.setFlag({ key: "stage_1_step_1", status: true });
      this.addScoreByRate({
        score: 10,
        characterName: this.character?.name,
        isMoveStage: false,
        hint_key: "stage_1_phase1_hint"
      });

      // this.phase1IgStory.autoplay = true;
    },
    phase2MsgClear: function() {
      if (this.flag("stage_1_phase2Clear")) {
        return;
      }
      this.setFlag({ key: "stage_1_phase2Clear", status: true });
      this.addScoreByRate({
        score: 20,
        characterName: this.character?.name,
        isMoveStage: false,
        hint_key: "stage_1_phase2_hint"
      });
    },
    phase2bonusDone: function() {
      this.setFlag({ key: "stage_1_step_2_favor", status: true });
    },
    parse1Hint() {
      this.hintMapAdd("stage_1_phase1_hint");
      this.phase1Messages.hintIndex++;
    },
    parse2Hint() {
      this.hintMapAdd("stage_1_phase2_hint");
      this.phase2Messages.hintIndex++;
    },
    showPhase: function(phase) {
      this.setFlag("stage_1_" + phase, true);
    },
    phase1CardNext: function() {
      this.setFlag({ key: "stage_1_step_1", status: true });
      this.setFlag({ key: "stage_1_step_1_favor", status: true });
    },
    goPhase1Map: function() {
      this.setFlag({ key: "stage_1_phase1ClearCard", status: false });

      //前往地圖的 code
    },
    phase3bonusDone: function() {
      this.setFlag({ key: "stage_1_step_3_favor", status: true });
      this.$router.replace("homeMap");
    },
    showStageFinishedFavor() {
      this.setFlag({ key: "stage_1_phase3Clear", status: true });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/__var";

.hint-img {
  width: 357px;
  max-width: 100%;
}
</style>
